export default {
  core: {
    locales: {
      ru: {
        header: 'Русский'
      },
      en: {
        header: 'Английский'
      },
    },

    themes: {
      dark: {
        header: 'Темная'
      },
      light: {
        header: 'Светлая'
      }
    },

    common: {
      noName: '<нет названия>'
    },

    commands: {
      navigateMain: {
        header: 'Рабочие столы'
      },
      navigateAdmin: {
        header: 'Администрирование'
      },
      logout: {
        header: 'Выйти'
      },
      version: {
        header: 'Версия'
      }
    },

    dialog: {
      commands: {
        ok: {
          header: 'OK'
        },
        cancel: {
          header: 'Закрыть'
        },
        yes: {
          header: 'Да'
        },
        no: {
          header: 'Нет'
        }
      },
      confirm: {
        header: 'Подтвердите действие',
        message: 'Все несохраненные изменения будут потеряны.'
      }
    },

    tables: {
      noData: 'Нет данных',
      loading: 'Загрузка...',
      loadingError: 'Ошибка при загрузке данных',
      notEnoughRights: 'Недостаточно прав для просмотра',
      commands: {
        filter: {
          header: 'Фильтр'
        }
      }
    },

    paginations: {
      total: 'Всего: {0}'
    },

    errors: {
      fields: {
        email: 'Введён некорректный адрес электронной почты',
        ipaddress: 'Поле не является IP-адресом',
        login: 'Латинские буквы, цифры (5-12 символов). Без пробелов и спецсимволов',
        number: 'Поле не является положительным числом',
        password: 'Пароль должен быть не менее 8 символов и содержать как минимум одну цифру и одну заглавную букву',
        passport: 'Поле не является номером паспорта',
        phone: 'Введён некорректный номер телефона',
        regexp: 'Поле не соответсвует заданному выражению',
        require: 'Обязательно для заполнения',
        unique: 'Запись с такими данными уже существует',
        rangeLess: 'Значение не может быть меньше {min}',
        rangeMore: 'Значение не может быть больше {max}',
        rangeOutside: 'Значение должно быть в диапазоне от {min} до {max}'
      },
      licenses: {
        widgetLicenseErrorText: 'Раздел недоступен. Требуется дополнительная лицензия.'
      }
    },
  },

  'eva-files-table': {
    caption: 'Файлы',
    columns: {
      download_url: {
        header: ' '
      },
      name: {
        header: 'Название'
      },
      extension: {
        header: 'Расширение'
      },
      size: {
        header: 'Размер'
      },
      folder: {
        header: 'Папка'
      }
    },
    commands: {
      add: {
        header: 'Добавить файл',
        success: 'Файл успешно добавлен',
        error: 'Во время добавления файла произошла ошибка'
      },
      edit: {
        header: 'Редактировать файл',
        success: 'Файл успешно отредактирован',
        error: 'Во время редактирования файла произошла ошибка'
      },
      remove: {
        header: 'Удалить файл',
        confirm: 'Удалить файл ?',
        success: 'Файл успешно удален',
        error: 'Во время удаления файла произошла ошибка'
      },
      copyAddress: {
        header: 'Скопировать адрес',
        success: 'Адрес скопирован в буфер обмена'
      }
    }
  }
}
